import {
    ApiReactiveService,
    type ApiServiceUseFetchOptions
} from '@composable-api/service/api.reactive-service'
import { ProductFiltersResponseModel } from '../models/product-filters-response.model'
import type { MaybeRef } from 'vue'
import type { ApiModel } from '@composable-api/api.model'
import type { ApiServiceFetchOptions } from '@composable-api/service/api.service'
import type { ProductsFilterSource } from '../types/resources'

class ProductsFilterApiReactiveService<M extends ApiModel> extends ApiReactiveService<M> {
    usePost<T extends boolean>(data: FilterPayload, options?: ApiServiceUseFetchOptions<M, T>) {
        return this.useFetch({
            ...options,
            method: 'POST',
            body: data,
        })
    }

    post(data: FilterPayload, options?: ApiServiceFetchOptions<M>) {
        return this.fetch({
            ...options,
            method: 'POST',
            body: data,
        })
    }
}

export default function useProductsFilterApiService() {
    return new ProductsFilterApiReactiveService({
        endpoint: '/products/filter',
    }, ProductFiltersResponseModel)
}

interface FilterPayload {
    request_uri: MaybeRef<string>
    source?: MaybeRef<keyof ProductsFilterSource>
}
