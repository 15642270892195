<template>
    <div>
        <CoreContainer class="sim-p-coll">
            <BaseContainerContent
                class="sim-pc-detail__content"
                :padding="{ top: 'none', bottom: 'large' }"
            >

                <div class="sim-pc-detail__info">
                    <h1 class="sim-h1 mb-6">
                        {{ title }}
                    </h1>

                    <slot />
                </div>

                <!--  FILTERS  -->
                <!--  a floating panel on the left side of the page with filters  -->
                <FilterPanel v-if="filtersResponse?.getItem()?.isFilterPanelVisible()"
                             :filters="filtersResponse?.getItem()?.getFilterBlocks() ?? []"
                             class="sim-pc-detail__filters"
                             :loading="areFiltersLoading"
                             @change="handleFiltersChange"
                />

                <div ref="productsListWrapperEl" class="sim-pc-detail__products">
                    <div v-if="filtersResponse?.getItem()?.getProducts().length" class="flex flex-wrap items-center justify-between gap-x-4 gap-y-8">
                        <UiPaginationInfo
                            v-if="filtersResponse?.getItem()?.paginationData"
                            t="filters.products_range"
                            :pagination="filtersResponse!.getItem()!.paginationData!"
                        />

                        <FilterSortSelect
                            v-if="filtersResponse?.getItem()?.sortOptions"
                            :options="filtersResponse!.getItem()!.sortOptions!"
                            @change="handleSortChange"
                        />
                    </div>

                    <hr class="mb-7 mt-4">

                    <FilterSelectedItems
                        v-if="areFiltersSelected"
                        :selected-filters="filtersResponse!.getItem()!.getSelectedFilters()!"
                        class="mb-8"
                        :loading="areFiltersLoading"
                        @change="handleFiltersChange"
                    />

                    <LayoutProductList v-if="filtersResponse?.getItem()?.getProducts().length">
                        <ProductCard
                            v-for="product in filtersResponse?.getItem()?.getProducts()"
                            :key="product.id!"
                            class="!w-full"
                            :product="product"
                            :loading="areFiltersLoading || areProductsLoading"
                        />
                    </LayoutProductList>

                    <div v-if="filtersResponse?.getItem()?.getProducts().length" class="mt-14 flex flex-col items-center justify-center gap-6 sm:flex-row sm:justify-between">
                        <BaseUiPagination
                            :aria-label="$t('accessibility.pagination_for_products')"
                            :number-of-pages="filtersResponse?.getItem()?.paginationData?.pages"
                            :current-page="filtersResponse?.getItem()?.paginationData?.page"
                            class="mr-auto"
                            @change="handlePaginationChange"
                        />

                        <BaseUiButton
                            v-if="!filtersResponse?.getItem()?.paginationData?.hasReachedLastPage()"
                            color="primary"
                            size="large"
                            :on-click="handleShowMoreItems"
                        >
                            <template #leading>
                                <IconArrowRight rotate="right" class="text-simplo" />
                            </template>

                            <i18n-t keypath="filters.display_more" scope="global">
                                <template #number>
                                    {{ filtersResponse?.getItem()?.paginationData?.perPage }}
                                </template>
                            </i18n-t>
                        </BaseUiButton>
                    </div>

                    <NoResultsComponent
                        v-else
                        image-url="/images/category.svg"
                        :title="areFiltersSelected ? $t('product.collection.filtered_empty') : $t('product.collection.empty')"
                        :subtitle="areFiltersSelected ? $t('product.collection.filtered_empty_search') : $t('search.products')"
                        class="mt-16"
                        :show-search="false"
                    />
                </div>

            </BaseContainerContent>
        </CoreContainer>

        <!--  NEWSLETTER SECTION  -->
        <SectionNewsletter />
    </div>
</template>

<script lang="ts" setup>
import type { ApiResponse } from '@composable-api/api.response'
import type { ProductFiltersResponseModel } from '@simploshop-models/product-filters-response.model'
import type { AsyncData } from '#app'

const {
    title,
    filtersResponse,
    refreshFilters,
} = defineProps<{
    title: string
    filtersResponse: ApiResponse<ProductFiltersResponseModel> | undefined
    refreshFilters: AsyncData<any, any>['refresh']
}>()

const areFiltersSelected = computed(() => {
    return filtersResponse?.getItem()?.getSelectedFilters().length
})

const route = useRoute()
const router = useRouter()

const modelValue = defineModel<string>()

// re-fetch products on navigation in history
watch(() => route.fullPath, (newVal) => {
    if (newVal === modelValue.value) return
    handleFiltersChange(newVal)
})

const productsListWrapperEl = useTemplateRef<HTMLElement>('productsListWrapperEl')

const areFiltersLoading = ref<boolean>(false)
async function handleFiltersChange(url: string) {
    areFiltersLoading.value = true
    await _applyFilters(url)
    areFiltersLoading.value = false
}

async function handleSortChange(url: string) {
    areProductsLoading.value = true
    await _applyFilters(url)
    areProductsLoading.value = false
}

/**
 * Sets the new url to filter the products with.
 * Also updates the url in the browser.
 *
 * !! THIS METHOD IS NOT MEANT TO BE CALLED FROM THE UI !!
 *
 * @param url the new url to filter with
 */
async function _applyFilters(url: string) {
    modelValue.value = url
    await refreshFilters()
    // redirect to the URI from BE if available (to hide `?page=1`, for example)
    await router.replace(filtersResponse?.getItem()?.requestUri ?? url)
}

const areProductsLoading = ref<boolean>(false)
async function handlePaginationChange(newPage: number) {
    const newUrl = filtersResponse?.getItem()?.paginationData?.getPageUrl(newPage)
    if (!newUrl) return
    areProductsLoading.value = true
    await _applyFilters(newUrl)
    areProductsLoading.value = false
    productsListWrapperEl.value?.scrollIntoView({ behavior: 'smooth' })
}


// FETCH MORE ITEMS
async function handleShowMoreItems() {
    const newUrl = filtersResponse?.getItem()?.paginationData?.getNextPageRangeUrl()
    if (!newUrl) return
    await _applyFilters(newUrl)
}

</script>

<style lang="scss" scoped>
@use "sass:math";

.sim-pc-detail__content {
    display: flex;
    flex-direction: column;
    gap: 3rem;

    @include more-than(lg) {
        display: grid;
        grid-template-columns: 20rem 1fr;
        gap: 2.75rem 3rem;
        grid-template-areas:
            "filters info"
            "filters products"
            "filters - ";
    }

    @include more-than-custom(1920) {
        grid-template-columns: 22rem 1fr;
    }
}

.sim-pc-detail__content--no-filters {
    grid-template-columns: 1fr;
    column-gap: 0;
    grid-template-areas:
            "info"
            "products"
            "- ";
}

.sim-pc-detail__info {
    grid-area: info;
}

.sim-pc-detail__filters {
    grid-area: filters;
    margin-bottom: auto;
}

.sim-pc-detail__products {
    grid-area: products;
    scroll-margin: 10rem;
}

</style>
