<script lang="tsx">
import defineComponentCoreUiColorSwatchSelector from '@core/app/components/core/ui/CoreUiColorSwatchSelector.vue'

export default defineComponentCoreUiColorSwatchSelector()

</script>

<style lang="scss" scoped>
@use "@core-scss/components/CoreUiSelector" as *;
@use "@core-scss/components/CoreUiColorSwatchSelector" as *;

$size: 1.875rem;
$outline-offset: 0.125rem;

@include wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 0.625rem - $outline-offset;
}

@include checkbox {
    width: $size;
    height: $size;
    border-radius: $size * 4;

    padding: $outline-offset;
    border: 1px solid transparent;
}

@include checkbox--checked {
    border: 1px solid black;

    @include accessibility-focus-outline-offset;
}

@include checkbox-background {
    position: absolute;
    inset: $outline-offset;
    border-radius: $size * 4;

    overflow: hidden;

    border: 1px solid rgb(0 0 0 / 0.1);
}
</style>
