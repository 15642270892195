<template>
    <div class="sim-filter-block">
        <!--  FILTER NAME (btn)  -->
        <button
            class="sim-filter-block__filter-name"
            :class="{'lg:pointer-events-none lg:outline-none': collapsible}"
            type="button"
            :tabindex="collapsible ? -1 : 0"
            @click="toggleExpanded"
        >
            <slot name="title">
                {{ title }}
            </slot>
            <IconChevron
                :up="isExpanded"
                size="xs"
                class="sim-c-simplo"
                :class="{'lg:hidden': collapsible}"
                aria-hidden
            />
        </button>

        <!--  FILTER CONTENT (collapsible)  -->
        <BaseUtilCollapse v-model="isExpanded" expanded-breakpoint="lg">
            <div class="sim-filter-block__content" :class="contentClass">
                <slot />
            </div>
        </BaseUtilCollapse>
    </div>
</template>

<script lang="ts" setup>

const {
    title,
    collapsible = false,
    expanded,
    contentClass,
} = defineProps<{
    title?: string | null | undefined
    collapsible?: boolean
    expanded?: boolean
    contentClass?: string
}>()

const isExpanded = ref<boolean>(expanded ?? !collapsible)
const isOverLgBreakpoint = useScssBreakpoints().greaterOrEqual('lg')

function toggleExpanded() {
    if (isOverLgBreakpoint.value) return
    isExpanded.value = !isExpanded.value
}

</script>

<style lang="scss" scoped>

.sim-filter-block {
    margin-bottom: 1rem;
}

.sim-filter-block__filter-name {
    @include sim-text-small;
    @include sim-font-bold;

    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 1rem 0;

    letter-spacing: 0.1em;
    text-transform: uppercase;

    border-bottom: 1px solid $sim-c-basic-400;
}

.sim-filter-block__content {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

</style>
