<script lang="tsx">
import type { PropType, SlotsType } from 'vue'
import type { ComponentOverrideOptions } from '@core-types/components'

export type BaseUiRangeProps<T> = {
    modelValue: number | [number, number]
    min: number
    max: number
    step?: number
    loading?: boolean
    disabled?: boolean
}

type BaseUiRangeSlots<T> = {}

type ComponentOptions = {}

export function defineComponentBaseUiRange<T>(options?: ComponentOverrideOptions<ComponentOptions, BaseUiRangeProps<T>, BaseUiRangeSlots<T>>) {
    return defineComponent(
        (props: BaseUiRangeProps<T>, ctx) => {

            const { t } = useI18n()

            const modelValue = computed({
                get() {
                    return props.modelValue
                },
                set(val: number | [number, number]) {
                    ctx.emit('update:modelValue', val)
                },
            })

            const isDual = computed(() => typeof props.modelValue !== 'number')
            const isDisabled = computed(() => props.disabled || props.loading)

            /**
             * Single slider value or end value for dual slider
             */
            const startRangeValue = computed({
                get() {
                    return typeof modelValue.value === 'number' ? modelValue.value : modelValue.value![0]
                },
                set(val) {
                    const value = Number(val)

                    modelValue.value = isDual.value
                        ? value > endRangeValue.value ? [endRangeValue.value, endRangeValue.value] : [value, endRangeValue.value]
                        : value
                },
            })

            /**
             * End value for dual slider
             */
            const endRangeValue = computed({
                get() {
                    return typeof modelValue.value === 'number' ? modelValue.value : modelValue.value![1]
                },
                set(val) {
                    const value = Number(val)

                    modelValue.value = isDual.value
                        ? value < startRangeValue.value ? [startRangeValue.value, startRangeValue.value] : [startRangeValue.value, value]
                        : value
                },
            })

            const style = useCssModule()
            const trackColor = style.trackColor
            const rangeColor = style.rangeColor

            const sliderStyles = computed(() => ({
                background: `linear-gradient(
                                 to right,
                                 ${trackColor} 0%,
                                 ${trackColor} ${typeof modelValue.value === 'number' ? 0 : (((modelValue.value?.[0] ?? props.min) - props.min) / (props.max - props.min)) * 100}%,
                                 ${rangeColor} ${typeof modelValue.value === 'number' ? 0 : (((modelValue.value?.[0] ?? props.min) - props.min) / (props.max - props.min)) * 100}%,
                                 ${rangeColor} ${typeof modelValue.value === 'number' ? modelValue.value : (((modelValue.value?.[1] ?? props.min) - props.min) / (props.max - props.min)) * 100}%,
                                 ${trackColor} ${typeof modelValue.value === 'number' ? modelValue.value : (((modelValue.value?.[1] ?? props.min) - props.min) / (props.max - props.min)) * 100}%,
                                 ${trackColor} 100%
                            )`,
            }))

            const isClicked = ref<boolean>(false)
            const isDragging = ref<boolean>(false)

            watch(modelValue, (val) => {
                if (!isDragging.value && !isClicked.value) {
                    if (Array.isArray(val) && val.length === 2) {
                        let [start, end] = val
                        if (start > end) {
                            [start, end] = [end, start]
                        }
                        start = Math.max(props.min, start)
                        end = Math.min(props.max, end)

                        // if the new values are different, update the model value
                        const [oldStart, oldEnd] = val
                        if (start !== oldStart || end !== oldEnd) {
                            modelValue.value = [start, end]
                        }
                    }
                }

                if (!isClicked.value) {
                    // handle the change event for click on the slider (wouldn't be triggered by the input change)
                    handleChange()
                    return
                }

                isDragging.value = true
            }, { deep: true })

            function handleChange() {
                ctx.emit('change', modelValue.value)
            }

            function onMouseDown() {
                if (isDisabled.value) return
                isClicked.value = true
            }

            function handleSliderClick(event: MouseEvent) {
                if (!isClicked.value || isDisabled.value) return

                isClicked.value = false

                if (isDragging.value) {
                    isDragging.value = false
                    return
                }

                const slider = event.target as HTMLDivElement
                const sliderWidth = slider.offsetWidth
                const sliderLeft = slider.getBoundingClientRect().left
                const clickPosition = event.clientX - sliderLeft
                const clickPercent = (clickPosition / sliderWidth) * 100
                let clickValue = Number(
                    // calculate value from percent
                    ((clickPercent / 100) * (props.max - props.min) + props.min)
                        // round to step
                        .toFixed(
                            props.step
                                ? props.step.toString().split('.')[1]?.length ?? 0
                                : 0
                        )
                )
                if (clickValue < props.min) clickValue = props.min
                if (clickValue > props.max) clickValue = props.max

                // if dual slider is not enabled, set the value and return
                if (typeof modelValue.value === 'number') {
                    startRangeValue.value = clickValue
                    return
                }

                const distanceFromStart = Math.abs(startRangeValue.value - clickValue)
                const distanceFromEnd = Math.abs(endRangeValue.value - clickValue)

                const isCloserToStartHandle = distanceFromStart < distanceFromEnd
                const isTheSameDistanceAndOnTheLeft = distanceFromStart === distanceFromEnd && clickValue < startRangeValue.value

                if (isCloserToStartHandle || isTheSameDistanceAndOnTheLeft) {
                    startRangeValue.value = clickValue
                } else {
                    endRangeValue.value = clickValue
                }
            }

            return () => (
                <div
                    class={[style['sim-range'], {
                        [`${style['sim-range--dragging']}`]: isDragging.value || isClicked.value,
                        [`${style['sim-range--disabled']}`]: isDisabled.value,
                        [`${style['sim-range--loading']}`]: props.loading,
                    }]}
                    onMousedown={onMouseDown}
                    onMouseup={handleSliderClick}
                >
                    <input
                        v-model={startRangeValue.value}
                        class={style['sim-range__el']}
                        type="range"
                        min={props.min}
                        max={props.max}
                        step={props.step}
                        style={sliderStyles.value}
                        aria-label={isDual.value ? t('_core_simploshop.accessibility.lower_bound') : undefined}
                        disabled={isDisabled.value}
                        onChange={handleChange}
                    />

                    {isDual.value && (
                        <input
                            v-model={endRangeValue.value}
                            class={`${style['sim-range__el']} ${style['sim-range__el--no-track']}`}
                            type="range"
                            min={props.min}
                            max={props.max}
                            step={props.step}
                            aria-label={t('_core_simploshop.accessibility.upper_bound')}
                            disabled={isDisabled.value}
                            onChange={handleChange}
                        />
                    )}
                </div>
            )
        },
        {
            props: {
                modelValue: {
                    type: [Number, Array] as PropType<BaseUiRangeProps<T>['modelValue']>,
                    default: options?.props?.modelValue?.default,
                    required: options?.props?.modelValue?.required ?? false,
                },

                min: {
                    type: Number as PropType<BaseUiRangeProps<T>['min']>,
                    default: options?.props?.min?.default,
                    required: options?.props?.min?.required ?? true,
                },
                max: {
                    type: Number as PropType<BaseUiRangeProps<T>['max']>,
                    default: options?.props?.max?.default,
                    required: options?.props?.max?.required ?? true,
                },
                step: {
                    type: Number as PropType<BaseUiRangeProps<T>['step']>,
                    default: options?.props?.step?.default,
                    required: options?.props?.step?.required ?? false,
                },
                loading: {
                    type: Boolean as PropType<BaseUiRangeProps<T>['loading']>,
                    default: options?.props?.loading?.default,
                    required: options?.props?.loading?.required ?? false,
                },
                disabled: {
                    type: Boolean as PropType<BaseUiRangeProps<T>['disabled']>,
                    default: options?.props?.disabled?.default,
                    required: options?.props?.disabled?.required ?? false,
                },
            },
            slots: Object as SlotsType<BaseUiRangeSlots<T>>,
            emits: {
                'update:modelValue': (value: number | [number, number]) => true,
                'change': (value: number | [number, number]) => true,
            },
        }
    )
}

export default defineComponentBaseUiRange()

</script>

<style lang="scss" module>
@use "@core-scss/components/BaseUiRange.scss" as *;

@include wrapper {
    height: 0.25rem;
}

@include set-track-background-color(gray);
@include set-track-range-color(red);

@include track {
    border-radius: 100vmax;
}

@include thumb {
    width: 1.25rem;
    height: 1.25rem;

    background-color: white;
    border: 2px black solid;
    border-radius: 100vmax;
}

</style>
