<template>
    <FilterBlock :title="filter.label"
                 collapsible
                 :expanded="expanded"
    >
        <BaseUiRange
            v-model="range"
            :min="filter.additionalProps?.min ?? 0"
            :max="filter.additionalProps?.max ?? 0"
            :loading="loading"
            @change="handleFilterChange"
        />

        <div class="mt-4 flex flex-row justify-between gap-4">
            <BaseUiInput
                v-model.lazy="range[0]"
                :min="filter.additionalProps?.min ?? 0"
                :max="filter.additionalProps?.max ?? 0"
                :disabled="isDisabled"
                type="number"
                variant="inline"
                size="none"
                autoresize
            >
                <template #trailing>
                    {{ currencySymbol }}
                </template>
            </BaseUiInput>
            <BaseUiInput
                v-model.lazy="range[1]"
                :min="filter.additionalProps?.min ?? 0"
                :max="filter.additionalProps?.max ?? 0"
                :disabled="isDisabled"
                type="number"
                variant="inline"
                size="none"
                autoresize
            >
                <template #trailing>
                    {{ currencySymbol }}
                </template>
            </BaseUiInput>
        </div>
    </FilterBlock>
</template>

<script lang="ts" setup>

const {
    filter,
    expanded,
    loading,
} = defineProps<{
    filter: InstanceType<typeof ProductFilterModel>
    expanded?: boolean
    loading?: boolean
}>()

const { currencySymbol } = useProperties()

const range = ref<[number, number]>([filter.additionalProps?.current_min ?? 0, filter.additionalProps?.current_max ?? 0])

const isDisabled = computed(() => loading)

const emit = defineEmits<{
    change: [url: string]
}>()

function handleFilterChange() {
    const url = filter.getRangeUrl(range.value)
    if (!url) return

    emit('change', url)
}

</script>

<style lang="scss" scoped>

</style>
