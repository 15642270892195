<template>
    <div class="flex flex-col gap-6">
        <template v-if="parsedDescription">
            <UiCollapseText v-if="parsedDescription.length > 400">
                <span class="sim-wysiwyg-text sim-category-info__text" v-html="parsedDescription" />
            </UiCollapseText>
            <span v-else class="sim-wysiwyg-text sim-category-info__text" v-html="parsedDescription" />
        </template>

        <div v-if="subCategories?.length" class="w-full">
            <BaseUiButton
                color="primary"
                size="large"
                class="w-full uppercase lg:hidden"
                @click="areSubCategoriesExpanded = !areSubCategoriesExpanded"
            >
                {{ $t('product.collection.show_n_subcategories', subCategories.length) }}
                <template #trailing>
                    <IconChevron :up="areSubCategoriesExpanded" size="xs" class="text-simplo" />
                </template>
            </BaseUiButton>

            <BaseUtilCollapse v-model="areSubCategoriesExpanded" expanded-breakpoint="lg">
                <div class="sim-subcategories mt-8">
                    <NuxtLink v-for="subCategory in subCategories"
                              :key="subCategory.id!"
                              :to="subCategory.urls ?? ''"
                    >
                        <UiCardWithImage
                            class="h-full"
                            :src="subCategory.thumbnailImageUrl ?? subCategory.imageUrl ?? null"
                            hover
                        >
                            <span class="sim-text-large sim-font-bold">
                                {{ subCategory.name }}
                            </span>
                        </UiCardWithImage>
                    </NuxtLink>
                </div>
            </BaseUtilCollapse>
        </div>
    </div>
</template>

<script lang="ts" setup>

const {
    description,
    subCategories,
    parseDescription = true,
} = defineProps<{
    description: string
    subCategories?: Partial<InstanceType<typeof CategoryModel>>[]
    parseDescription?: boolean
}>()

const parsedDescription = computed(() => parseDescription ? parseNewlinesIntoBr(description) : description)

const areSubCategoriesExpanded = ref<boolean>(false)

</script>

<style scoped lang="scss">
.sim-subcategories {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;

    @include more-than(sm) {
        grid-template-columns: repeat(2, 1fr);
    }

    @include more-than(xl) {
        grid-template-columns: repeat(3, 1fr);
    }

    @include more-than(xxl) {
        grid-template-columns: repeat(4, 1fr);
    }

    @include more-than-custom(1920) {
        grid-template-columns: repeat(5, 1fr);
    }
}

.sim-category-info__text {
    @include more-than(md) {
        line-height: 1.875rem;
    }
}

</style>
