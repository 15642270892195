<template>
    <div :class="{ 'sim-filters--loading': loading }">
        <div v-for="filter in filters"
             :key="filter.id!"
        >

            <FilterBlockRange
                v-if="filter.isRangeType()"
                :filter="filter"
                :expanded="!!filter.containsActiveItems"
                :loading="loading"
                @change="handleFilterChange"
            />

            <FilterBlockColor
                v-if="filter.isColorType()"
                :filter="filter"
                :expanded="!!filter.containsActiveItems"
                @change="handleFilterChange"
            />

            <FilterBlockCheckbox
                v-if="filter.isCheckboxType()"
                :filter="filter"
                :expanded="!!filter.containsActiveItems"
                @change="handleFilterChange"
            />
        </div>
    </div>
</template>

<script lang="ts" setup>

const {
    filters,
    loading,
} = defineProps<{
    filters: InstanceType<typeof ProductFilterModel>[]
    loading: boolean
}>()

const emit = defineEmits<{
    change: [url: string]
}>()

function handleFilterChange(url: string) {
    emit('change', url)
}

</script>

<style lang="scss" scoped>

.sim-filters--loading {
    opacity: 0.5;
    cursor: wait;

    & * {
        pointer-events: none;
    }
}

</style>
