<script lang="tsx">
import { defineComponentBaseUiRange } from '@core/app/components/base/ui/BaseUiRange.vue'

export default defineComponentBaseUiRange()

</script>

<style lang="scss" module>
@use "@core-scss/components/BaseUiRange" as *;

@include wrapper {
    height: 0.25rem;
}

@include set-track-background-color($sim-c-basic-400);
@include set-track-range-color($sim-c-primary-800);

@include track {
    border-radius: 100vmax;
}

@include thumb {
    width: 1.25rem;
    height: 1.25rem;

    background-color: $sim-c-white;
    border: 2px $sim-c-black solid;
    border-radius: 100vmax;
}

</style>
